import React, { useState, useEffect } from "react";
import qs from "query-string";
import { useForm, FormProvider } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";

import Dialog from "@ui-kit/Dialog";
import ChildProfile from "@components/ChildProfile";
import { FormFields } from "@components/ChildProfile/types";
import Button from "@ui-kit/Button";
import { ROUTES } from "@constants";
import { createCss } from "./styles";
import { CHILDREN_API, Models } from "@services/api";
import { useNotificationStore } from "@store/NotificationStore";
import { must } from "@utils/must";
import { goToFormError } from "@helpers/goToFormError";
import { QUERIES } from "@constants/queries";

interface IParams {
  childId: string;
}

const ChildProfilePage: React.FC = () => {
  const [, { setUnknownErrorNotification }] = useNotificationStore();
  const methods = useForm<FormFields>();
  const { handleSubmit, formState, reset } = methods;
  const history = useHistory();
  const { childId } = useParams<IParams>();
  const [child, setChild] = useState<Models.Child | null>(null);
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const css = createCss();

  useEffect(() => {
    const fetchData = async () => {
      if (childId !== "new") {
        try {
          setLoading(true);

          const childResponse = await CHILDREN_API.childrenByIdGet({
            id: parseInt(childId, 10),
          });

          reset({
            birthday: childResponse.data.birthday,
            firstName: childResponse.data.firstName,
            gender: childResponse.data.gender,
            lastName: childResponse.data.lastName,
            medicalInformation: childResponse.data.medicalInformation || "",
            immunizationRecord:
              childResponse.data.documents?.immunizationRecord,
            medicalHistory: childResponse.data.documents?.medicalHistory,
            photo: childResponse.data.photo,
          });

          setChild(childResponse.data);
        } catch (error) {
          if (error.response.status === 404) {
            history.replace({
              pathname: ROUTES.CHILDREN_LIST,
              search: qs.stringify({ profileClosed: true }),
            });
          } else {
            setUnknownErrorNotification();
            throw error;
          }
        } finally {
          setLoading(false);
        }
      }
    };

    void fetchData();
  }, []);

  const submitForm = handleSubmit(
    async ({ medicalHistory, immunizationRecord, ...values }) => {
      if (child) {
        await CHILDREN_API.childrenByIdUpdate({
          child: {
            ...child,
            ...values,
            documents: {
              medicalHistory: medicalHistory || undefined,
              immunizationRecord: immunizationRecord || undefined,
            },
          },
          id: must(child.id),
        });
      } else {
        await CHILDREN_API.childrenCreate({
          child: {
            ...values,
            documents: {
              medicalHistory: medicalHistory || undefined,
              immunizationRecord: immunizationRecord || undefined,
            },
          },
        });
      }

      history.replace({
        pathname: ROUTES.CHILDREN_LIST,
      });
      void queryClient.invalidateQueries(QUERIES.CHILDREN_LIST);
    },
    () => setTimeout(goToFormError),
  );

  return (
    <Dialog
      open
      onClose={() => {
        history.replace({
          pathname: ROUTES.CHILDREN_LIST,
          search: qs.stringify({ profileClosed: true }),
        });
      }}
      title="Child profile"
      data-test="child-profile"
      loading={loading}
    >
      <FormProvider {...methods}>
        <ChildProfile
          disabledFields={{
            birthday: child !== null,
          }}
          styles={{
            root: css.childProfileRoot,
          }}
          showMedicalInformation
          showPhoto
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          loading={formState.isSubmitting}
          data-test="submit-btn"
          onClick={submitForm}
          css={css.submitButton}
        >
          Save changes
        </Button>
      </FormProvider>
    </Dialog>
  );
};

export default ChildProfilePage;
