import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  childProfileRoot: (theme) => ({
    [theme.mediaRequests.notMobile]: {
      width: 400,
    },
  }),

  submitButton: {
    marginTop: 30,
  },
});
